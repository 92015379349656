<template>
  <div>
    <header-view
      :title="'Editing ' + species.latin_name"
      :previous_page="
        admin ? '/speciesAdmin/view/' + uuid : '/species/view/' + uuid
      "
      :btnOptions="[
        {
          name: 'Save',
          action: this.handleSubmit,
        },
      ]" />
    <main-container class="ma-3">
      <v-form ref="form" @submit.prevent="handleSubmit" id="editSpeciesForm">
        <v-layout align-start justify-start column>
          <v-flex xs12>
            <v-text-field
              id="common_name"
              v-model="species.common_name"
              label="Common Name"
              :rules="validate_common_name"></v-text-field>
          </v-flex>
          <v-flex xs12 v-if="admin">
            <v-text-field
              label="Latin Name"
              id="latin_name"
              v-model="species.latin_name"
              :rules="validate_latin_name"></v-text-field>
          </v-flex>
          <v-autocomplete
            label="Region"
            :items="speciesRegions.slice(1)"
            v-if="admin"
            item-text="text"
            item-value="value"
            v-model="species.region"
            multiple
            chips
            clearable
            :return-object="false"></v-autocomplete>
          <v-flex xs12>
            <v-switch
              color="button-primary"
              v-model="species.toggleAttr"
              :label="toggleLabel"></v-switch>
          </v-flex>
        </v-layout>
      </v-form>
    </main-container>
  </div>
</template>

<script>
  import Species from '@/mixins/species';
  import HeaderView from '@/components/header-view';
  import MainContainer from '@/components/main-container';

  // services
  import Tenants from '@/services/Tenants.service.js';

  export default {
    name: 'AppSpeciesEdit',
    components: {
      'header-view': HeaderView,
      'main-container': MainContainer,
    },
    mixins: [Species],
    props: {
      admin: {
        default: false,
        type: Boolean,
      },
    },
    data() {
      var toggleAttr = this.admin ? 'verified' : 'active';
      return {
        toggleAttr: toggleAttr,
        species: {
          common_name: '',
          latin_name: '',
          tenantSpeciesUUID: '',
          species_uuid: '',
          active: true,
          region: [],
        },
        uuid: '',
        loading4: false,
        validate_common_name: [(v) => !!v || 'This is required'],
        validate_latin_name: [(v) => !!v || 'This is required'],
      };
    },
    computed: {
      toggleLabel: function () {
        if (this.species.hasOwnProperty('toggleAttr')) {
          return (
            this.capitalize(this.toggleAttr) +
            ': ' +
            this.species.toggleAttr.toString()
          );
        } else {
          return '';
        }
      },
    },
    async mounted() {
      this.uuid = this.$route.params.uuid;
      if (this.admin) {
        this.species = await this.getSingleMasterSpecies(this.uuid);
      } else {
        this.species = await this.getSingleTenantSpecies(this.uuid);
      }
    },
    methods: {
      async handleSubmit() {
        if (!this.$refs.form.validate()) {
          return false;
        }
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        var payload = {};

        // update master species if admin
        if (this.admin) {
          payload.common_name = this.capitalize(this.species.common_name);
          payload.latin_name = this.capitalize(this.species.latin_name);
          payload.verified = this.species.toggleAttr;
          payload.region = this.species.region;

          const res = await Tenants.updateMasterSpecies(
            this.uuid,
            payload,
            accessToken
          );

          if (res) {
            this.$router.push({
              name: 'AdminSpeciesView',
              params: {
                uuid: this.uuid,
              },
            });
          } else {
            console.log('could not update species!');
          }

          // otherwise update tenant species
        } else {
          payload.species_uuid = this.species.species_uuid;
          payload.alt_common_name = this.capitalize(this.species.common_name);
          payload.active = this.species.toggleAttr;

          const res = await Tenants.updateTenantSpecies(
            this.uuid,
            payload,
            accessToken
          );

          if (res) {
            this.$router.push({
              name: 'SpeciesView',
              params: {
                uuid: this.uuid,
              },
            });
          } else {
            console.log('could not update species!');
          }
        }
      },
    },
  };
</script>
